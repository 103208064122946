import { Skeleton, Typography } from '@packages/shared';
import { ResponsiveCarousel } from '@packages/shared/src/components/Carousel/ResponsiveCarousel';
import { SlotBox } from '@packages/shared/src/components/SlotBox';
import type { ComponentProps, ReactNode } from 'react';

export type RecoCarouselProps = {
  /** Recommendation items */
  children: ReactNode;
  /** Displayed title above the items */
  title: string;
} & ComponentProps<typeof ResponsiveCarousel>;

/**
 * Base component for Product / Category / Brand sliders
 * */
export const RecoCarousel = ({ children, title, loadingState, ...rest }: RecoCarouselProps) => (
  <div>
    {title.length > 0 && (
      <Typography
        sx={{ px: [1.5, 1.5, 2], my: 2, typography: ['h4', 'h4', 'h3'] }}
        component="header"
      >
        {loadingState ? <Skeleton variant="text" width="15%" /> : title}
      </Typography>
    )}
    <SlotBox sx={{ p: [1.5, 1.5, 2], pb: [1.5, 1.5, 2] }}>
      <ResponsiveCarousel
        {...rest}
        showDots={false}
        hasOffset
        mobile={{ visibleSlides: 2.5, slidesToScroll: 2, gap: 1.5 }}
        tablet={{ visibleSlides: 3.5, slidesToScroll: 3, gap: 1.5 }}
        desktop={{
          visibleSlides: 5.5,
          slidesToScroll: 5,
          gap: 1.5,
        }}
      >
        {children}
      </ResponsiveCarousel>
    </SlotBox>
  </div>
);
