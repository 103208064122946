/** Style Box Sizes */
const StyleBoxSizeSmall = {
  width: '234px',
  height: '234px',
};
const StyleBoxSizeMedium = {
  width: '258px',
  height: '258px',
};

/** StyleType1a */
export const StyleType1aBasics = {
  ...StyleBoxSizeSmall,
  left: {
    xs: '2%',
    md: '5%',
  },
};

/** StyleType1b */
export const StyleType1bBasics = {
  ...StyleBoxSizeSmall,
  right: {
    xs: '2%',
    md: '5%',
  },
};

/** StyleType2a */
export const StyleType2aBasics = {
  ...StyleBoxSizeMedium,
  left: 0,
};

/** StyleType2b */
export const StyleType2bBasics = {
  ...StyleBoxSizeMedium,
  right: 0,
};

/** StyleType3a */
export const StyleType3aBasics = {
  ...StyleBoxSizeSmall,
  left: '5%',
};

/** StyleType3b */
export const StyleType3bBasics = {
  ...StyleBoxSizeSmall,
  right: '5%',
};

/** StyleType4a */
export const StyleType4aBasics = {
  ...StyleBoxSizeMedium,
  left: {
    xs: '5%',
    md: '2%',
  },
};

/** StyleType4b */
export const StyleType4bBasics = {
  ...StyleBoxSizeMedium,
  right: {
    xs: '5%',
    md: '2%',
  },
};
