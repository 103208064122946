const stableMargin = {
  xs: '0.813rem',
  md: '1.25rem',
};

export const StyleTypeLeft = {
  left: stableMargin,
  transformOrigin: 'left',
};

export const StyleTypeRight = {
  right: stableMargin,
  transformOrigin: 'right',
};

export const StyleTypeTopLeft = {
  top: stableMargin,
  left: stableMargin,
  transformOrigin: 'left top',
};

export const StyleTypeTopRight = {
  top: stableMargin,
  right: stableMargin,
  transformOrigin: 'right top',
};

export const StyleTypeBottomLeft = {
  left: stableMargin,
  bottom: stableMargin,
  transformOrigin: 'left bottom',
};

export const StyleTypeBottomRight = {
  right: stableMargin,
  bottom: stableMargin,
  transformOrigin: 'right bottom',
};
