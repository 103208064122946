/* istanbul ignore file */
// import { event } from '../../EventBridge';

type PapiData = {
  gender: {
    predictedFemaleProbability: number;
    behavouralFemaleIntraday: number;
  };
  hotArticlesIntraday: string[];
  hotBrandsIntraday: string[];
  installmentAffinity: number;
  psegment: string;
  psegmentBAUR: string;
  psegmentUNITO: string;
};

// TODO: check and implement event bridge
export const getPapidata = <K extends keyof PapiData, R extends PapiData[K]>(
  key: K,
  defaultReturnValue: R,
): R => {
  const papiSession: PapiData = JSON.parse(
    typeof window !== 'undefined' ? (window.sessionStorage.getItem('papi') ?? 'null') : 'null',
  );

  if (!papiSession) {
    // TODO: check event bridge
    // event.one('papiReady', (papiReadyData: PapiData) => {
    //   if (typeof window !== 'undefined' && papiReadyData) {
    //     if (!window.sessionStorage.getItem('papi')) {
    //       window.sessionStorage.setItem('papi', JSON.stringify(papiReadyData));
    //     }
    //     if (papiReadyData?.[key]) setPapiData(papiReadyData[key] as R);
    //   }
    // });
  }

  return papiSession?.[key] ? (papiSession[key] as R) : defaultReturnValue;
};
