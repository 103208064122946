import type { SxProps, Theme } from '@mui/system';
import { cmsFontToTheme } from '@packages/shared/src/utils/cmsFontToTheme';
import { FontSizeVariants, LineHeightVariants } from './types';
import {
  StyleType1aBasics,
  StyleType1bBasics,
  StyleType2aBasics,
  StyleType2bBasics,
  StyleType3aBasics,
  StyleType3bBasics,
  StyleType4aBasics,
  StyleType4bBasics,
} from './styles';

export const styleHelperFontSize = (type: string) => {
  switch (type) {
    case 'big':
      return FontSizeVariants.Big;
    case 'medium':
      return FontSizeVariants.Medium;
    default:
      return FontSizeVariants.Small;
  }
};

export const styleHelperLineHeight = (type: string) => {
  switch (type) {
    case 'big':
      return LineHeightVariants.Big;
    case 'medium':
      return LineHeightVariants.Medium;
    default:
      return LineHeightVariants.Small;
  }
};

export const styleHelperType = (
  styleType: string,
  styleHelperVerticalAlignment: string,
): SxProps<Theme> => {
  const styleTypeDefault = {
    ...StyleType3aBasics,
    transformOrigin: 'left',
  };

  switch (styleType) {
    case '1a':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType1aBasics,
          transformOrigin: 'top left',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType1aBasics,
          transformOrigin: 'bottom left',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType1aBasics,
          transformOrigin: 'left',
        };
      }
      return styleTypeDefault;
    case '1b':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType1bBasics,
          transformOrigin: 'top right',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType1bBasics,
          transformOrigin: 'bottom right',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType1bBasics,
          transformOrigin: 'right',
        };
      }
      return styleTypeDefault;
    case '2a':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType2aBasics,
          transformOrigin: 'top left',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType2aBasics,
          transformOrigin: 'bottom left',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType2aBasics,
          transformOrigin: 'bottom left',
        };
      }
      return styleTypeDefault;
    case '2b':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType2bBasics,
          transformOrigin: 'top right',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType2bBasics,
          transformOrigin: 'bottom right',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType2bBasics,
          transformOrigin: 'right',
        };
      }
      return styleTypeDefault;
    case '3a':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType3aBasics,
          transformOrigin: 'top left',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType3aBasics,
          transformOrigin: 'bottom left',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return styleTypeDefault;
      }
      return styleTypeDefault;
    case '3b':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType3bBasics,
          transformOrigin: 'top right',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType3bBasics,
          transformOrigin: 'bottom right',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType3bBasics,
          transformOrigin: 'right',
        };
      }
      return styleTypeDefault;
    case '4a':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType4aBasics,
          transformOrigin: 'top left',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType4aBasics,
          transformOrigin: 'bottom left',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType4aBasics,
          transformOrigin: 'left',
        };
      }
      return styleTypeDefault;
    case '4b':
      if (styleHelperVerticalAlignment === 'top') {
        return {
          ...StyleType4bBasics,
          transformOrigin: 'top right',
        };
      }
      if (styleHelperVerticalAlignment === 'bottom') {
        return {
          ...StyleType4bBasics,
          transformOrigin: 'bottom right',
        };
      }
      if (styleHelperVerticalAlignment === 'center') {
        return {
          ...StyleType4bBasics,
          transformOrigin: 'right',
        };
      }
      return styleTypeDefault;
    default:
      return styleTypeDefault;
  }
};

export const styleFinalVerticalAlignment = (styleHelperVerticalAlignment: string) => {
  switch (styleHelperVerticalAlignment) {
    case 'top':
      return { top: '11%' };
    case 'bottom':
      return { bottom: '11%' };
    default:
      return { top: 'auto' };
  }
};

export const styleHelperAlignment = (alignment: string) => {
  switch (alignment) {
    case 'left':
      return { left: 0 };
    case 'right':
      return { right: 0 };
    default:
      return { left: '50%' };
  }
};

export const styleHelperVerticalAlignment = (verticalAlignment: string) => {
  switch (verticalAlignment) {
    case 'center':
    case 'stretch':
      return { top: '50%' };
    case 'flex-end':
    case 'bottom':
      return { bottom: 0 };
    default:
      return { top: 0 };
  }
};

export const styleHelperTransformHorizontal = (alignment: string) => {
  switch (alignment) {
    case 'left':
    case 'right':
      return 'translateX(0)';
    default:
      return 'translateX(-50%)';
  }
};

export const styleHelperTransformVertical = (verticalAlignment: string) => {
  switch (verticalAlignment) {
    case 'center':
    case 'stretch':
      return 'translateY(-50%)';
    default:
      return 'translateY(0)';
  }
};

export const containerStyle = (alignment: string, verticalAlignment: string): SxProps<Theme> => ({
  position: 'absolute',
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  transform: `${styleHelperTransformHorizontal(alignment)} ${styleHelperTransformVertical(
    verticalAlignment,
  )}`,
  pointerEvents: 'none',
  ...styleHelperAlignment(alignment),
  ...styleHelperVerticalAlignment(verticalAlignment),
});

export const containerStyleInner = (
  styleType: string,
  styleAlignment: string,
  styleVerticalAlignment: string,
  styleBorderColor: string,
  styleBackgroundColor: string,
  styleScalingFactor: number,
): SxProps<Theme> => ({
  '&': cmsFontToTheme,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  padding: 2,
  overflow: 'hidden',
  textAlign: 'left',
  position: 'absolute',
  textDecoration: 'none',
  '> a': {
    textDecoration: 'none',
  },
  border: `2px solid ${styleBorderColor}`,
  backgroundColor: styleBackgroundColor,
  justifyContent: styleAlignment,
  alignContent: styleVerticalAlignment,
  transform: {
    xs: `scale(${styleScalingFactor - 0.33})`,
    md: `scale(${styleScalingFactor - 0.3})`,
    lg: styleScalingFactor === 1 ? 'none' : `scale(${styleScalingFactor})`,
  },
  ...styleHelperType(styleType, styleVerticalAlignment),
  ...styleFinalVerticalAlignment(styleVerticalAlignment),
});
