import { Box, ConditionalWrapper, Link, useTrackCampaign } from '@packages/shared';
import { useRef } from 'react';
import { buildLinkWithArguments } from '../../utils/buildLinkWithArguments';
import type { OverlayCircleType } from './types';
import { OverlayCircleItem } from './OverlayCircleItem';
import { containerStyle, containerStyleInner } from './utils';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';

/**
 * This component displays round overlays in different layout variants on static pages.
 *
 * @param data Object contains data
 * @returns JSX.Element to be placed in PageLayout
 */
export const OverlayCircle = ({ data }: { data: OverlayCircleType }) => {
  const {
    behavior_paragraph_overlay_scale: styleScalingFactor,
    behavior_paragraph_overlay_layout: styleType,
    behavior_paragraph_background_color: styleBackgroundColor,
    behavior_paragraph_link_target: linkTarget,
    field_link: link,
    field_tracking: linkTracking,
    field_link_anchor: linkAnchor,
    field_link_highlight: linkHighlight,
    field_link_searchfilter: linkFilter,
    field_bonus_number: linkBonusNumber,
  } = data;

  const tmpLink = buildLinkWithArguments(link, {
    field_tracking: linkTracking,
    field_link_anchor: linkAnchor,
    field_link_highlight: linkHighlight,
    field_link_searchfilter: linkFilter,
    field_bonus_number: linkBonusNumber,
    field_link_target: linkTarget,
  });

  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(tmpLink);
  const teaserRef = useRef<HTMLSpanElement>(null);
  const { trackClick } = useTrackCampaign(data, teaserRef, finalLink);

  return (
    <Box sx={containerStyle()} component="span">
      <Box
        ref={teaserRef}
        component="div"
        sx={containerStyleInner({
          styleType,
          styleBackgroundColor,
          styleScalingFactor: styleScalingFactor && parseFloat(styleScalingFactor),
        })}
      >
        <ConditionalWrapper
          condition={!!link}
          wrapper={(ch) => (
            <Link href={finalLink} onClick={() => trackClick()}>
              {ch}
            </Link>
          )}
        >
          <OverlayCircleItem data={data} />
        </ConditionalWrapper>
      </Box>
    </Box>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.OverlayCircle]: OverlayCircle });
