import type { ParagraphMeta, TimeControl } from '../../../interfaces';

export enum PlaceholderTypes {
  NewsletterAgnitas = 'newsletter-agnitas',
  ContactForm = 'contactForm',
  RateProtectionType = 'formRsv',
  RevocationForm = 'formRevocation',
}

export type PlaceholderType = ParagraphMeta &
  TimeControl & {
    type: 'placeholder';
    field_type: 'placeholder';
    field_placeholder_variants: 'newsletter-agnitas' | 'contactForm' | 'formRsv' | 'formRevocation';
  };
