import type { SxProps, Theme } from '@mui/system';
import { cmsFontToTheme } from '@packages/shared/src/utils/cmsFontToTheme';
import {
  StyleTypeBottomLeft,
  StyleTypeBottomRight,
  StyleTypeLeft,
  StyleTypeRight,
  StyleTypeTopLeft,
  StyleTypeTopRight,
} from './styles';

export const styleHelperType = (styleType: string): SxProps<Theme> => {
  switch (styleType) {
    case 'left':
      return StyleTypeLeft;
    case 'top-left':
      return StyleTypeTopLeft;
    case 'top-right':
      return StyleTypeTopRight;
    case 'bottom-left':
      return StyleTypeBottomLeft;
    case 'bottom-right':
      return StyleTypeBottomRight;
    case 'right':
    default:
      return StyleTypeRight;
  }
};

export const containerStyle = (): SxProps<Theme> => ({
  position: 'absolute',
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  left: 0,
  top: 0,
  pointerEvents: 'none',
});

export const containerStyleInner = ({
  styleType = 'left',
  styleBackgroundColor,
  styleScalingFactor = 1,
}: {
  styleType?: string;
  styleBackgroundColor?: string;
  styleScalingFactor?: number;
}): SxProps<Theme> => ({
  '&': cmsFontToTheme,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 2,
  overflow: 'hidden',
  textAlign: 'center',
  position: 'absolute',
  textDecoration: 'none',
  '> a': {
    textDecoration: 'none',
  },
  width: {
    xs: '7.625rem',
    md: '15.25rem',
  },
  height: {
    xs: '7.625rem',
    md: '15.25rem',
  },
  backgroundColor: styleBackgroundColor || 'primary.dark',
  borderRadius: '50%',
  transform: {
    xs: `scale(calc(${styleScalingFactor} + 0.35))`,
    lg: styleScalingFactor === 1 ? 'none' : `scale(${styleScalingFactor})`,
  },
  top: 'auto',
  ...styleHelperType(styleType),
});
