import { Box, ConditionalWrapper, Link, useTrackCampaign } from '@packages/shared';
import { useRef } from 'react';
import { buildLinkWithArguments } from '../../utils/buildLinkWithArguments';
import type { OverlayRectangleType } from './types';
import { OverlayRectangleItem } from './OverlayRectangleItem';
import { containerStyle, containerStyleInner } from './utils';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';

/**
 * This component displays flexible teasers in different layout variants on static pages.
 *
 * @param data Object contains data
 * @returns JSX.Element to be placed in PageLayout
 */
export const OverlayRectangle = ({ data }: { data: OverlayRectangleType }) => {
  const {
    field_scaling_factor: styleScalingFactor = 1,
    field_layout_variant: styleType = '0',
    field_border_color: styleBorderColor = 'rgba(0,0,0,0.5)',
    field_background_color: styleBackgroundColor = 'rgba(255,255,255,0.7)',
    field_alignment: styleAlignment = 'center',
    field_vertical_alignment: styleVerticalAlignment = 'center',
    field_link: link,
    field_tracking: linkTracking,
    field_link_anchor: linkAnchor,
    field_link_highlight: linkHighlight,
    field_link_searchfilter: linkFilter,
    field_bonus_number: linkBonusNumber,
  } = data;

  const tmpLink = buildLinkWithArguments(link, {
    field_tracking: linkTracking,
    field_link_anchor: linkAnchor,
    field_link_highlight: linkHighlight,
    field_link_searchfilter: linkFilter,
    field_bonus_number: linkBonusNumber,
  });

  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(tmpLink);
  const teaserRef = useRef<HTMLSpanElement>(null);
  const { trackClick } = useTrackCampaign(data, teaserRef, finalLink);

  return (
    <Box sx={containerStyle(styleAlignment, styleVerticalAlignment)} component="span">
      <Box
        ref={teaserRef}
        component="span"
        sx={containerStyleInner(
          styleType,
          styleAlignment,
          styleVerticalAlignment,
          styleBorderColor,
          styleBackgroundColor,
          styleScalingFactor,
        )}
      >
        <ConditionalWrapper
          condition={!!link}
          wrapper={(ch) => (
            <Link href={finalLink} onClick={() => trackClick()}>
              {ch}
            </Link>
          )}
        >
          <OverlayRectangleItem data={data} />
        </ConditionalWrapper>
      </Box>
    </Box>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.OverlayRectangle]: OverlayRectangle });
